const opportunities = document.getElementsByClassName("opportunity");
const opportunityTeamSelect = document.getElementById("opportunityTeam");
const opportunityLocationSelect = document.getElementById("opportunityLocation");
const remotePreferenceSelect = document.getElementById("remotePreference");

const filterOpportunities = () => {
  const teamFilter = opportunityTeamSelect.value;
  const locationFilter = opportunityLocationSelect.value;
  const remoteFilter = remotePreferenceSelect.value;

  console.info(`🔍 Filter ${opportunities.length} Opportunities | Team '${teamFilter || "Any"}' - Location '${locationFilter || "Any"}' - Remote Preference '${remoteFilter || "Any"}'`);

  for (let i = 0; i < opportunities.length; i++) {
    const opportunity = opportunities[i];

    // 1. Reset all opportunities to visible
    opportunity.classList.remove("hidden");

    // 2. If no filters are selected, return early
    if (!teamFilter && !locationFilter && !remoteFilter) continue;

    // 2. Filter out location mismatches
    if (locationFilter) {
      const officeState = opportunity.dataset.state || "";
      const requiredStates = JSON.parse(opportunity.dataset.states || "[]");

      if ([officeState, ...requiredStates].every(state => state !== locationFilter)) {
        opportunity.classList.add("hidden");
        continue;
      }
    }

    // 3. Filter out team (industry) mismatches
    const industries = JSON.parse(opportunity.dataset.industries || "[]");
    if (teamFilter && !industries.includes(teamFilter)) {
      opportunity.classList.add("hidden");
      continue;
    }

    // 4. Filter out remote mismatches
    const remotePreference = opportunity.dataset.remote || "";
    if ((remoteFilter && !remotePreference) ||
      (remoteFilter === "Offers Remote" && !remotePreference.startsWith("Remote")) ||
      (remoteFilter === "On-Site Only" && remotePreference !== "On-Site Only")) {
      opportunity.classList.add("hidden");
    }
  }
};

opportunityTeamSelect.addEventListener("change", filterOpportunities);
opportunityLocationSelect.addEventListener("change", filterOpportunities);
remotePreferenceSelect.addEventListener("change", filterOpportunities);
